import React, { useRef, useEffect, useState } from "react";
import Hls from "hls.js";
import { useSelector, useDispatch } from "react-redux";
import {
  hidePopupVideoPlayer,
  setHidePopupVideoPlayerList,
  setEndedVideoPlayerList,
  updatePlaytime,
  updatePlaytimeLastWeek,
  updatePlaytimeLastWeekSelected,
} from "../redux/exerciseVideos";
import {
  completeVideoPlayPercentage,
  minimumVideoPlayPercentage,
  updateFrequency,
} from "../constants/defaultValues";

const VideoPlayerListByteArk = ({
  url,
  day_number,
  video_number,
  selectedVDO,
  lastWeekVDO_click,
  lastWeekVDOAll,
  lastWeekStart,
  selectExerciseVideoLastWeek,
  isCurrentWeek,
}) => {
  const dispatch = useDispatch();
  const hidePopUpVideoPlayer = useSelector(({ exerciseVideos }) =>
    exerciseVideos ? exerciseVideos.hidePopUpVideoPlayer : ""
  );
  const exerciseVideo = useSelector(({ exerciseVideos }) =>
    exerciseVideos ? exerciseVideos.exerciseVideo : ""
  );
  const all_exercise_activity = useSelector(({ exerciseVideos }) =>
    exerciseVideos ? exerciseVideos.all_exercise_activity : ""
  );
  const exerciseVideoLastWeek = useSelector(({ exerciseVideos }) =>
    exerciseVideos ? exerciseVideos.exerciseVideoLastWeek : ""
  );
  const endedVideoPlayerList = useSelector(({ exerciseVideos }) =>
    exerciseVideos ? exerciseVideos.endedVideoPlayerList : ""
  );
  const user = useSelector(({ authUser }) => (authUser ? authUser.user : ""));
  const videoRef = useRef(null);
  const [videoEnded, setVideoEnded] = useState(false); // เพิ่ม state สำหรับตรวจสอบว่าวีดีโอถูกดูจบหรือไม่
  const [videoCurrDuration, setVideoCurrDuration] = useState(0); // เพิ่ม state สำหรับเก็บระยะเวลาที่เล่นไปของวีดีโอ
  const [videoDuration, setVideoDuration] = useState(0); // เพิ่ม state สำหรับเก็บความยาวของวีดีโอ
  const [prevPlayTime, setPrevPlayTime] = useState(0);
  const [hlsInstance, setHlsInstance] = useState(null);
  const [availableQualities, setAvailableQualities] = useState([]);
  const [currentQuality, setCurrentQuality] = useState(-1); // ค่าเริ่มต้นเป็น -1 เพื่อแสดงว่าเป็น Auto


  useEffect(() => {
    addEventListenerVideo();

    setVideoCurrDuration(0);
    setVideoEnded(false);
    dispatch(setEndedVideoPlayerList(false));
  }, [selectedVDO]);

  const addEventListenerVideo = () => {
    const video = videoRef.current;

    if (video && selectedVDO) {
      if (Hls.isSupported()) {
        const hls = new Hls();
        hls.loadSource(selectedVDO?.url); // ใช้ URL ที่ถูกส่งเข้ามาใน props
        hls.attachMedia(video);
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          const levels = hls.levels.map((level, index) => ({
            label: `${level.height}p`, // แสดงคุณภาพตามความสูงของ video (เช่น 1080p, 720p)
            index: index,
          }));
          setAvailableQualities([{ label: "Auto", index: -1 }, ...levels]);
          setHlsInstance(hls);
          video && video.play();
        });
        hls.on(Hls.Events.ERROR, (event, data) => {
          console.error("HLS error:", data);
        });
      } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
        video.src = selectedVDO?.url; // ใช้ URL ที่ถูกส่งเข้ามาใน props


        video && video.addEventListener("canplay", () => {
          video.play();
        });
      }

      video.addEventListener("ended", () => {
        setVideoEnded(true); // กำหนดว่าวีดีโอถูกดูจบ
      });

      video.addEventListener("loadedmetadata", () => {
        const videoDuration = video.duration; // ความยาวของวีดีโอ (ในวินาที)
        setVideoDuration(videoDuration);
      });

      video.addEventListener("timeupdate", () => {
        setVideoCurrDuration(video.currentTime); // อัปเดตระยะเวลาที่คลิปถูกเล่นไป
      });
    }
  };

  useEffect(() => {
    if (videoEnded) {
      //ส่่งไปบอกหน้า videoList2 ว่าวีดีโอเล่นจบ
      dispatch(setEndedVideoPlayerList(true));
    }
  }, [videoEnded]);

  useEffect(() => {
    //เช็คคลิปตอน 99.9% แทนการเช็ควีดีโอจบ
    if (videoCurrDuration / videoDuration >= 0.999) {
      setVideoEnded(true);
    }
    //ทำการหน่วงเวลาตาม updateFrequency เพื่อยิง updatePlayTime
    const diffTime = Math.abs(videoCurrDuration - prevPlayTime);
    if (diffTime < updateFrequency) {
      return;
    }
    setPrevPlayTime(videoCurrDuration);
    //เช็คว่าถ้าดูวีดีโอยังไม่ถึง minimumVideoPlayPercentage ไม่ต้อง updatePlayTime
    //เช็คว่าถ้าเคยดูคลิปนั้นจบแล้ว ไม่ต้อง updatePlayTime
    if (
      videoCurrDuration / videoDuration < minimumVideoPlayPercentage ||
      (selectedVDO?.play_time / selectedVDO?.duration >=
        completeVideoPlayPercentage)
    ) {
      return;
    }
    updatePlayTime();
  }, [videoCurrDuration]);

  const updatePlayTime = () => {
    if (!isCurrentWeek) {
      //updatePlayTime ของผู้ใช้ต่ออายุดูย้อนหลัง
      const tempExerciseVideoLastWeekSelect = [...selectExerciseVideoLastWeek];
      const tempExerciseVideoLastWeekAll = [...all_exercise_activity];
      tempExerciseVideoLastWeekSelect[day_number][video_number] = {
        ...tempExerciseVideoLastWeekSelect[day_number][video_number],
        play_time: videoDuration,
        duration: videoDuration,
      };
      tempExerciseVideoLastWeekAll[lastWeekStart - 1].activities =
        JSON.stringify(tempExerciseVideoLastWeekSelect);

      dispatch(
        updatePlaytimeLastWeekSelected(
          user.user_id,
          user.start_date,
          user.expire_date,
          day_number,
          video_number,
          videoDuration,
          videoDuration,
          tempExerciseVideoLastWeekAll,
          lastWeekStart
        )
      );
    } else {
      //updatePlayTime ของผู้ใช้ต่ออายุดูคลิปปัจจุบัน
      const tempExerciseVideo = [...exerciseVideo];
      tempExerciseVideo[day_number][video_number] = {
        ...tempExerciseVideo[day_number][video_number],
        play_time: videoDuration,
        duration: videoDuration,
      };

      dispatch(
        updatePlaytime(
          user.user_id,
          user.start_date,
          user.expire_date,
          day_number,
          video_number,
          videoDuration,
          videoDuration,
          tempExerciseVideo
        )
      );
    }
  };

  const handleVideoClose = () => {
    const video = videoRef.current;
    const hls = new Hls();

    if (video) {
      video.pause();
      hls.destroy();
      selectedVDO = null;
      // และอื่น ๆ ที่คุณต้องการให้เกิดขึ้นเมื่อปิดวีดีโอ
    }

    //สั่ง set ตัวแปรใน redux และให้หน้า videoList ไปเช็ีคจากตัวแปรนั้นเพื่อซ่อน popup
    dispatch(setHidePopupVideoPlayerList(true));
  };


  const handleQualityChange = (qualityIndex) => {
    if (hlsInstance) {
      if (qualityIndex === -1) {
        hlsInstance.currentLevel = -1; // ตั้งค่าเป็น auto
      } else {
        hlsInstance.currentLevel = qualityIndex; // ตั้งค่าระดับคุณภาพตาม index ที่เลือก
      }
      // อัปเดต currentQuality ให้เป็นค่า index ที่เลือก (เพื่อแสดงค่าใน dropdown)
      setCurrentQuality(qualityIndex);
    }
  };



  return (
    <div>
      <video id="videoPlayerList" ref={videoRef} controls playsInline />
      <div style={{
        display: "flex",
        position: "absolute",
        bottom: "35px",
        left: "24px",
      }}>
        <label htmlFor="qualitySelect" style={{ marginRight: "8px" }}>Quality: </label>
        <select
          id="qualitySelect"
          value={currentQuality}
          onChange={(e) => handleQualityChange(parseInt(e.target.value))}
        >
          {availableQualities.map((quality, index) => (
            <option key={index} value={quality.index}>
              {quality.label}
            </option>
          ))}
        </select>
      </div>
      <img
        alt=""
        src="../assets/img/thumb/close.png"
        className="close"
        id="autoClickLink"
        onClick={handleVideoClose}
      ></img>
    </div>
  );
};

export default VideoPlayerListByteArk;

